import React from 'react'
import { graphql } from 'gatsby'
import { TransitionState } from "gatsby-plugin-transition-link"
import TransitionLink from "gatsby-plugin-transition-link"
import posed from "react-pose"
import { motion } from "framer-motion"
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import TriggerButton from '../components/TriggerButton'

const PageFade = posed.div({
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
})
const elementFade = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1
  }
}
const sequenceFade = {
  hidden: {
    opacity: 0,
    y: "10px"
  },
  visible: i => ({
    opacity: 1,
    y: 0,
    transition: {
      duration: .6,
      delay: i * 0.3,
      ease: "easeInOut"
    },
  })
}

const AboutPage = (props) => {

  // console.log("about props ", props)
  const metadata = props.data.aboutpage.metadata
  const { heading, copy, background_images } = props.data.prismicAbout.data
  const aboutCopy = props.data.prismicLayout.data.about_rakuten_copy

  return (
    <Layout props={props}>
      <TransitionState>
        {({ transitionStatus }) => (
          <PageFade
            pose={
              ["entering", "entered"].includes(transitionStatus)
                ? "visible"
                : "hidden"
            }
          >
            <SEO
              locale={props.pageContext.lang}
              title={metadata.metatitle}
              description={metadata.description}
              keywords={metadata.keywords}
              image={metadata.metadata_image.localFile.childImageSharp.fixed.src}
              pathname={props.location.pathname}
              origin={props.location.origin}
            />
            <motion.div
              className="about-page_header"
              initial="hidden"
              animate="visible"
              variants={elementFade}>
              <TransitionLink className="header__logo" to="/" exit={{ length: 0.5 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="108px" height="33px" viewBox="0 0 108 33">
                  <path fill="#ffffff" d="m86.6 27.8-59.8 5.2-5.2-5.2h65zm-62.7-7.1c2.2 0 3.8-2.2 3.8-5s-1.6-5-3.8-5c-2.2 0-3.9 2.2-3.9 5s1.6 5 3.9 5zm3.8-13.8h4v17.6h-4v-0.8c-1.1 0.8-2.4 1.2-3.8 1.2-4.6 0-8-4.1-8-9.2s3.5-9.2 8-9.2c1.4 0 2.7 0.4 3.8 1.2v-0.8zm32 0h4v17.6h-4v-0.7c-1 0.7-2.2 1.1-3.4 1.1-4.3 0-7.2-3.5-7.2-7.7v-10.4h4v10.4c0 1.9 1.3 3.6 3.3 3.6s3.3-1.6 3.3-3.6v-10.4zm37.3 17.6h-4v-17.6h4v0.7c1-0.7 2.2-1.1 3.4-1.1 4.2 0 7.2 3.5 7.2 7.7v10.4h-4v-10.4c0-1.9-1.3-3.6-3.3-3.6-1.9 0-3.3 1.6-3.3 3.6v10.4zm-92.6-17.8v6.8h2.7c1.2 0 2.4-0.6 3.1-1.7 0.6-1.1 0.6-2.4 0-3.5-0.6-1.1-1.8-1.7-3.1-1.7h-2.7zm0 17.8h-4.2v-22h6.9c3.3 0 6.2 2.1 7.2 5.3 1 3.1-0.1 6.6-2.8 8.5l6.2 8.2h-5.2l-5.1-6.8h-3v6.8zm70.1-4.2 2.6 3.5c-1.2 0.7-2.5 1.1-3.9 1.1-3 0-5.9-2.4-5.9-6.3v-7.6h-2.1v-4.2h2.1v-4.4h4v4.4h3.4v4.2h-3.4v7.6c0 1.7 1.2 2.2 1.9 2.2 0.5 0 0.9-0.2 1.3-0.4zm-32.9-5.2 8 9.4h-5.6l-5.9-7.2v7.2h-4.1v-23.5h4.1v12l4.8-6.1h5.6l-6.9 8.3zm45.6-1.7c-0.8-4.4-6.3-4.1-7.2 0h7.2zm-3.5-7c4.6 0 8.6 4.2 7.7 10.5h-11.5c0.5 4 5.1 6.1 7.8 2l3.5 2c-2.3 3.2-5 3.9-7.1 3.9-4.2 0-8.4-3.8-8.4-9.3 0-5.2 3.3-9.3 8-9.3z"/>
                </svg>
              </TransitionLink>
            </motion.div>
            <motion.div
              className="about-page wrapper is-fluid"
              initial="hidden"
              animate="visible"
              variants={elementFade}>
              <TriggerButton 
                icon="close" 
                color="white" 
                url="/" 
                copy={aboutCopy} />
              <div className="columns is-desktop is-vcentered">
                <div className="column is-8 is-8-desktop is-offset-2 is-offset-2-desktop">
                  <div className="about-page__content content has-text-centered">
                    <motion.h1
                      className="is-size-2 is-size-1-fullhd"
                      custom="1"
                      initial="hidden"
                      animate="visible"
                      variants={sequenceFade}>
                        {heading.text}
                    </motion.h1>
                    <motion.div
                      className="copy"
                      custom="2"
                      initial="hidden"
                      animate="visible"
                      variants={sequenceFade}>
                        <div dangerouslySetInnerHTML={{ __html: copy.html }} />
                    </motion.div>
                  </div>
                </div>
                <motion.div
                  className="about-page__images"
                  custom="4"
                  initial="hidden"
                  animate="visible"
                  variants={sequenceFade}>
                  {background_images.map((image, index) => {
                    return (
                      <BackgroundImage
                        className={`about-page__image about-page__image--${index}`}
                        fluid={image.background_image.localFile.childImageSharp.fluid}
                        key={index}
                      />
                    )
                  })}
                </motion.div>
              </div>
            </motion.div>
          </PageFade>
        )}
      </TransitionState>
    </Layout>
  )
}

export const aboutPageData = graphql`
  query AboutPageQuery($id: String! $uid: String! $lang: String!) {
    aboutpage : prismicAboutBodyMetadata(id: {eq: $id}) {
      metadata : primary {
        metatitle
        description
        keywords
        metadata_image {
          localFile {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
      }
    }
    prismicAbout(uid: {eq: $uid}, lang: {eq: $lang}) {
      data {
        title {
          text
        }
        heading {
          text
        }
        copy {
          html
        }
        background_images {
          background_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1400, pngQuality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    prismicLayout(lang: {eq: $lang}) {
      data {
        about_rakuten_copy
      }
    }
  }
`

export default AboutPage
